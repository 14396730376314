import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Layout from "../../components/Layout/index";
import * as Icons from "../../icons";
import "./CaseStudy.scss";

const CaseStudyComponent = () => {
  const [caseStudyData, setCaseStudyData] = useState([]);

  const data = useStaticQuery(graphql`
    {
      allContentfulCaseStudy(sort: { fields: createdAt }) {
        edges {
          node {
            id
            title
            subTitle
            image {
              gatsbyImageData
            }
            content {
              content
            }
            detailsUrlLabel
          }
        }
      }
    }
  `);

  useEffect(() => {
    setCaseStudyData(data["allContentfulCaseStudy"]["edges"]);
  }, [data]);

  return (
    <Layout>
      <div className="caseStudyContainer">
        <div className="caseStudy">
          <div className="caseStudy__header">
            <h1>Case Studies</h1>
            <p>
              Learn how hrSense customers are reduce 60% of mechanical work to
              hire employee
            </p>
          </div>

          <div className="caseStudy__body">
            {caseStudyData &&
              caseStudyData.map((item, index) => (
                <div className="body" key={index}>
                  <div className="body__section">
                    <div className="body__content">
                      <div className="icon">
                        <Icons.HangoutIcon />
                      </div>
                      <h5>{item.node.subTitle}</h5>
                      <h2>{item.node.title}</h2>
                    </div>

                    <div className="body__image">
                      <img
                        src={
                          item["node"].image.gatsbyImageData.images.fallback.src
                            ? item["node"].image.gatsbyImageData.images.fallback
                                .src
                            : "./../static/caseStudy/case-study.png"
                        }
                        alt="Case Study"
                      />

                      <div className="paragraph">
                        <p>{item["node"].content.content}</p>

                        <Link to="/">
                          {item["node"].detailsUrlLabel} <Icons.NextArrowIcon />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CaseStudyComponent;
